import React,{useState, useEffect} from 'react';
import Layout from '../components/layout';
import { Link} from 'gatsby';
import SEO from "../components/seo.jsx"

import { Helmet } from 'react-helmet';
import { useTheme } from '../context/ThemeContext';


export const Head = () => (
  <SEO 
      title="STAIRAZ: A Professional Team in AI tech and Career Consulting"
      description="     
      STAIRAZ: Expert team with vast professional experience in high-tech & career consulting. Committed to affordable, top-quality service.
      "
      pathname="/about-us/"

      >
        <html lang="en" />
 <meta name="keywords" content="AI-Powered Service, Job Application, Cover Letter Generators, Free Tools, STAIRAZ"/>
  </SEO>
);





const AboutUs = ({data}) => {
  const { isDarkMode } = useTheme();

  useEffect(() => {
    const link = document.getElementById('mode-stylesheet');
    if (link) {
      const additionalLink = document.createElement('link');
      additionalLink.rel = 'stylesheet';
      additionalLink.href = isDarkMode ? '/styles/page.css' : '/styles/pageLight.css';
      document.head.appendChild(additionalLink);

      return () => {
        document.head.removeChild(additionalLink);
      };
    }
  }, [isDarkMode]);

  
  return (
    <Layout>
       <Helmet>
        <link
          rel="stylesheet"
          href={isDarkMode ? '/styles/page.css' : '/styles/pageLight.css'}
        />
      </Helmet>
      <section className="section">
      
      <h1 className="section-heading">Meet the STAIRAZ Team: <br/>Pioneering Excellence in Career Consulting and AI Innovation</h1>
    <p className="section-content" >Hello from STAIRAZ! Our team is full of energy and packed with experts in career guidance and artificial intelligence (AI). Over half of our members have PhDs and years of experience in high-tech fields, universities, and career consulting. We founded this company with a clear mission: to provide the most professional service at the lowest cost, ensuring the best user experience by seamlessly integrating our expertise in career consulting with cutting-edge AI technology. Come and see how we're making a difference in career consulting!
</p>
       
       
      </section>
      <div className='divide-line'></div>

    </Layout>
  );
};

export default AboutUs;
